<template>
  <v-card max-width="426" class="mx-auto px-3 px-md-6 py-6">
    <h1 class="font-medium-20 gray13--text text-center mb-2">
      {{ $t("auth.loginPage.resetPassword.resetPassword") }}
    </h1>

    <p class="gray7--text text-center mb-6">
      {{ $t("auth.loginPage.resetPassword.enterNewPassword") }}
    </p>
    <v-form ref="form" v-model="valid" @submit.prevent="submit">
      <v-text-field
        name="newPassword"
        v-model="resetPasswordModel.newPassword"
        @input="validatePassword"
        @focus="reInitValidation"
        @blur="reInitValidation"
        :rules="passwordRules"
        required
        :type="showPassword ? 'text' : 'password'"
        :label="$t('auth.loginPage.resetPassword.newPassword')"
        filled
        hide-details="auto"
        class="theme-custom-input mb-5"
        :class="{ 'mb-2': isFocused || isBlurred }"
      >
        <template v-slot:append>
          <div @click="showPassword = !showPassword" class="cursor-pointer">
            <v-icon size="24" v-if="showPassword">$eyeShow</v-icon>
            <v-icon size="24" v-else>$eyeHidden</v-icon>
          </div>
        </template>
      </v-text-field>

      <div v-if="isFocused" class="mb-5">
        <div class="font-regular-12 gray12--text mb-1">
          {{ $t("auth.loginPage.resetPassword.passwordRegexTitle") }}
        </div>

        <ul class="custom-list">
          <li v-for="item in regexErrors" :key="item.type">
            <v-badge
              :color="item.color"
              dot
              inline
              class="badge-size"
            ></v-badge>

            <span class="font-regular-10 gray9--text">{{ item.message }}</span>
          </li>
        </ul>
      </div>

      <v-text-field
        name="confirmNewPassword"
        v-model="resetPasswordModel.confirmNewPassword"
        :rules="passwordConfirmationRules"
        required
        :type="showPasswordConfirmation ? 'text' : 'password'"
        :label="$t('auth.loginPage.resetPassword.confirmPassword')"
        filled
        hide-details="auto"
        class="theme-custom-input mb-4"
      >
        <template v-slot:append>
          <div
            @click="showPasswordConfirmation = !showPasswordConfirmation"
            class="cursor-pointer"
          >
            <v-icon size="24" v-if="showPasswordConfirmation">$eyeShow</v-icon>
            <v-icon size="24" v-else>$eyeHidden</v-icon>
          </div>
        </template>
      </v-text-field>

      <v-btn
        :disabled="!valid"
        color="primary"
        block
        depressed
        height="56"
        :loading="loading"
        type="submit"
      >
        <span class="font-semiBold-14">
          {{ $t("auth.loginPage.resetPassword.confirm") }}
        </span>
      </v-btn>
    </v-form>
  </v-card>
</template>

<script>
import { AuthService } from "@/services";

export default {
  name: "reset-password",
  components: {},
  props: [],
  data() {
    return {
      validInput: true,
      loading: false,
      valid: false,
      showPassword: false,
      showPasswordConfirmation: false,
      resetPasswordModel: {
        newPassword: "",
        confirmNewPassword: "",
        resetPasswordToken: "",
      },
      passwordRules: [
        (v) =>
          !!v || this.$t("auth.loginPage.resetPassword.newPasswordNotEmpty"),
        (v) =>
          (v && this.validInput) ||
          this.$t("auth.loginPage.resetPassword.invalidPassword"),
      ],
      validationRegex: [],
      matchedCase: [],
      validationMessage: "",
      regexErrors: [],
      isFocused: false,
      isBlurred: false,
    };
  },
  computed: {
    passwordConfirmationRules() {
      const rules = [];

      if (!this.resetPasswordModel.confirmNewPassword) {
        const required = (v) =>
          !!v ||
          this.$t("auth.loginPage.resetPassword.confirmPasswordNotEmpty");

        rules.push(required);
      }

      if (this.resetPasswordModel.newPassword) {
        const match = (v) =>
          (!!v && v) === this.resetPasswordModel.newPassword ||
          this.$t("auth.loginPage.resetPassword.passwordMustMatch");

        rules.push(match);
      }

      return rules;
    },
  },
  watch: {
    "resetPasswordModel.newPassword": "validateFields",
  },
  mounted() {
    this.getPasswordRegex();
  },
  methods: {
    validateFields() {
      this.$refs.form.validate();
    },
    getPasswordRegex() {
      AuthService.passwordRegex()
        .then((res) => {
          this.validationRegex = res.data.data;
        })
        .then(() => {
          this.validationRegex.forEach((elem) => {
            let regex = elem.regex;
            regex = regex.substring(1, regex.length - 1);
            this.matchedCase.push({
              message: elem.message,
              type: elem.type,
              regex: regex,
              color: "gray9",
            });
          });
        });
    },
    validatePassword(value) {
      this.regexErrors = [];
      this.validInput = true;
      this.matchedCase.forEach((elem) => {
        if (value.match(elem.regex)) {
          if (this.isFocused) {
            elem.color = "success";
          }
          this.regexErrors.push(elem);
        } else {
          if (this.isFocused) {
            elem.color = "gray9";
            this.validInput = false;
          }
          this.regexErrors.push(elem);
        }
      });
    },
    reInitValidation(event) {
      if (event.type === "focus") {
        this.isFocused = true;
        this.regexErrors = [...this.matchedCase];
      } else if (event.type === "blur") {
        this.isBlurred = true;
        this.validatePassword(this.resetPasswordModel.newPassword);
      }
    },
    submit() {
      this.loading = true;
      this.resetPasswordModel.resetPasswordToken =
        localStorage.getItem("otp_token");

      AuthService.resetPassword(this.resetPasswordModel)
        .then((res) => {
          this.$toast.success(res.data.message, {
            icon: this.$vuetify.icons.values.checkMarkDone,
          });
          localStorage.removeItem("otp_token");
          this.$router.replace("/auth/login");
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped lang="scss">
@import "ResetPassword";
</style>
